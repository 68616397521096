import React from "react"
import { Box, Flex, SimpleGrid, Stack } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  IntroText,
  PrimaryPagesHero,
  SectionHeading,
  TwoCols,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  CancersWeTreat,
  HighlightedTreatment,
  PatientsStories,
  QuickLinks,
  ResourcesCard,
  Seo,
  Services,
} from "../../components/elements"

const PatientsVisitors = ({ data, pageContext }) => {
  const page = data.datoCmsCancerPatientsVisitor
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image && page.seo.image.url}
      />

      <PrimaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </PrimaryPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container>
            <Box width="full">
              <IntroText>{page.introContent}</IntroText>
            </Box>
            <Stack direction={["column", "column", "row"]} spacing={12}>
              <Box width={["full", "full", "1 / 2"]} alignSelf="center">
                <TwoCols
                  content={`${page.contentNode.childMarkdownRemark.html}`}
                />
              </Box>
            </Stack>
          </Container>
        </Section>

        {/* Cancers We Treat */}
        <Section id="cancers" style={{ paddingTop: 0 }}>
          <Container>
            <Box width="full" textAlign="center">
              <SectionHeading>Cancers We Treat</SectionHeading>
            </Box>
            <Box width="full">
              <SimpleGrid
                columns={[1, 1, 2, 3]}
                spacing={[3, 3, 6]}
                sx={{ a: { textDecoration: "none" } }}
              >
                <CancersWeTreat />
              </SimpleGrid>
            </Box>
          </Container>
        </Section>

        {/* Services & Treatments */}
        <Section bg="lightgray">
          <Container>
            <Box pb={20}>
              <Box width="full" textAlign="center">
                <SectionHeading>Highlighted Treatment</SectionHeading>
              </Box>
              <HighlightedTreatment />
            </Box>

            <Flex flexWrap="wrap" id="services">
              <Box width="full" textAlign="center">
                <SectionHeading>Treatments & Services</SectionHeading>
              </Box>

              <Box width="full">
                <SimpleGrid
                  columns={[1, 1, 2, 3]}
                  spacing={[3, 3, 6]}
                  sx={{ a: { textDecoration: "none" } }}
                >
                  <Services />
                </SimpleGrid>
              </Box>
            </Flex>
          </Container>
        </Section>

        <Section id="resources">
          <Container>
            <ResourcesCard />

            <Flex flexWrap="wrap" pt={16}>
              <Box width="full">
                <SectionHeading textAlign="center">
                  For Your Convenience
                </SectionHeading>

                <QuickLinks page={page} />
              </Box>
            </Flex>
          </Container>
        </Section>

        <Section id="resources">
          <Container>
            <Box width="full" textAlign="center">
              <SectionHeading>Patient Stories</SectionHeading>
            </Box>
            <PatientsStories />
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default PatientsVisitors

export const query = graphql`
  {
    datoCmsCancerPatientsVisitor {
      title
      slug
      contentNode {
        childMarkdownRemark {
          html
        }
        id
      }
      introContent
      quicklinks {
        externalLinkUrl
        id
        internalLinkUrl
        linkTitle
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
